const getPostUrl = (post, isPreview = false) => {
  const tags = post.tags || [];

  return `/${getPageType(tags, isPreview)}/${post.slug}/`;
};

const getPageType = (tags, isPreview) => {
  if (isPreview) {
    return "preview";
  }

  const tagNames = tags.map((tag) => tag.name);
  if (tagNames.includes("article")) {
    return "blog/articles";
  } else if (tagNames.includes("devnote")) {
    return "blog/dev-notes";
  } else if (tagNames.includes("career")) {
    return "careers";
  }
};

module.exports = {
  getPostUrl,
};
